import Storage from "@/util/Storage";
import {delLimitProduct, getLimitProduct} from "@/Api/ShopAdmin";
import {Toast} from "vant";


export function getLimitTimeList(upData:any={}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return (
        Promise.resolve(getLimitProduct({ userId,token },upData).then(res=>{
            return res.data.productList || [];
        }))
    )
}


export function delLimitTime(id:string|number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return (
        Promise.resolve(delLimitProduct({ userId,token },{ id })).then(res=>{
            if ( res.message.code == "200" ) Toast("删除成功");
            return res.data
        })
    )
}
